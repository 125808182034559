<template>
  <div class="footer">
    <div class="ball" ref="ball"></div>
    <div class="btns">
      <router-link
        v-for="tab in tabItems"
        :key="JSON.stringify(tab.key)"
        class="btn"
        :class="{
          selected: isCurrent(tab.key)
        }"
        :to="Functions.Utils.arrayToString(tab.key)"
      >
        <div
          class="content"
          :ref="
            el => {
              divs[Functions.Utils.arrayToString(tab.key)] = el;
            }
          "
        >
          <Icon v-if="tab.icon" :icon="tab.icon" />
          <span class="label" v-text="tab.label"></span>
        </div>
      </router-link>
    </div>
    <div class="search" @click="toggleSearch">
      <Button v-bind="Functions.Presets.Button.Full" icon="magnifying-glass" />
      <div class="spotlight">
        <div class="shade"></div>
        <div class="oval"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick, onMounted, onBeforeUpdate, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useEmitter from "@/composables/useEmitter";
import _ from "lodash";
import Functions from "@/functions";
import Tabs from "../../../router/tabs.js";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
export default {
  name: "FooterNav",
  components: {
    Button,
    Icon
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const emitter = useEmitter();
    const divs = ref({});
    const ball = ref(null);

    const tabItems = computed(() => {
      return Tabs[store.getters["User/topRole"]];
    });

    const isCurrent = key => {
      let current = false;
      let keys = key;
      if (_.isString(key)) {
        keys = [key];
      }
      keys.forEach(k => {
        if (route.path === k) {
          current = true;
        }
      });
      return current;
    };

    const moveBall = id => {
      if (!_.isString(id)) {
        id = route.path;
      }
      tabItems.value.forEach(item => {
        if (isCurrent(item.key)) {
          id = Functions.Utils.arrayToString(item.key);
        }
      });
      nextTick(() => {
        let el = divs.value[id];
        if (el) {
          let x = el.getBoundingClientRect().x || el.getBoundingClientRect().left;
          let w = el.getBoundingClientRect().width;
          let position = x + w / 2;
          position = (position / document.body.scrollWidth) * 100;
          ball.value.style.left = position - 10 + "vw"; //-10 1/2 ball size
          ball.value.style.top = -1 + "vh";
        } else {
          ball.value.style.top = "0vh";
        }
      });
    };

    const toggleSearch = () => {
      emitter.emit("toggleSearch");
    };

    watch(
      () => route.path,
      val => {
        moveBall(val);
      }
    );

    onMounted(() => {
      store.dispatch("User/getCurrent");
      moveBall();
      window.addEventListener("resize", moveBall);
    });

    onBeforeUpdate(() => {
      divs.value = {};
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", moveBall);
    });

    return {
      Functions,
      divs,
      ball,
      tabItems,
      isCurrent,
      toggleSearch
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.footer {
  z-index: z("footer");
  flex-grow: 0 !important;
  height: 60px;
  @include flexCentered();
  color: $color-light-gray;
  background: $color-blue;

  @include mq($from: desktop) {
    display: none;
  }

  @include mq($to: medium) {
    font-size: 0.8em;
    height: 50px;
  }

  .ball {
    position: absolute;
    width: 20vw;
    height: 60px;
    background: $color-blue;
    border-radius: 99em;
    @include transition;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    left: 5vw;

    @include mq($to: medium) {
      height: 50px;
    }
  }

  .btns {
    @include flexCentered();
    align-items: stretch;
    width: 100%;
    height: 100%;

    .btn {
      position: relative;
      display: inline-block;
      overflow: hidden;
      @include transition;
      @include flexCentered(column);
      flex-grow: 1;
      height: 100%;
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      & + .btn {
        border-left: 1px solid rgba($color-black, 0.3);
      }

      .content {
        @include flexCentered(column);
      }

      .icon {
        @include transition;
        margin-bottom: addSpace(2);
      }
      .label {
        @include transition;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 0.85em;
      }

      &.selected,
      &:hover {
        .icon {
          color: $color-light;
        }
      }
      &.selected .label {
        color: $color-light;
      }
    }
  }

  .search {
    position: relative;
    margin: addSpace(-8) addSpace(1) addSpace(1) addSpace(-4);
    &:deep(.button) {
      padding: addSpace(3);
      &:hover {
        border-radius: 2em;
      }
    }
    .spotlight {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      pointer-events: none;
      z-index: z("flat");
      .shade {
        position: relative;
        width: 0;
        height: 0;
        left: 25%;
        bottom: 20%;
        border-left: 22px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 45px solid rgba($color-black, 0.1);
        transform: rotate(5deg);
      }
      .oval {
        position: absolute;
        width: 60%;
        height: 20%;
        left: 20%;
        bottom: 20%;
        background: rgba($color-black, 0.1);
        border-radius: 60%;
      }
    }
  }
}
</style>
