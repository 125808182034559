<template>
  <div class="headerSort">
    <div
      class="option"
      :class="{ selected: opt.selected }"
      v-for="(opt, k) in parsedOptions"
      :key="k"
      @click="$emit('sort', opt)"
    >
      <span v-text="opt.label || opt"></span>
      <Icon :icon="orderByDirection == Constants.ORDER_DIRECTION.DESC ? 'arrow-up' : 'arrow-down'" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { computed } from "vue";
import Icon from "@/components/atoms/Icon.vue";
import Constants from "@/assets/constants";
export default {
  name: "HeaderSort",
  props: {
    options: { type: [String, Array], default: null },
    orderBy: { type: String, default: null },
    orderByDirection: { type: String, default: null }
  },
  components: {
    Icon
  },
  setup(props) {
    const parsedOptions = computed(() => {
      let opts = props.options;
      if (_.isString(opts)) {
        opts = [opts];
      }
      opts.forEach(o => {
        o.selected = o.key === props.orderBy;
      });
      return opts;
    });

    return {
      Constants,
      parsedOptions
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.headerSort {
  white-space: nowrap;
  .option {
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    & + .option {
      margin-left: addSpace(2);
    }

    &.selected {
      font-weight: 400;
      color: $color-blue;
    }

    .icon {
      @include transition;
    }
    &:not(.selected) .icon {
      width: 0;
    }
  }
}
</style>
