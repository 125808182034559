<template>
  <div class="select-input-field" :class="classObject" @keydown="onKeyPress">
    <label class="select-input-field__label" v-if="label" v-t="label"></label>
    <div class="select-input-field__hint" v-if="hint" v-t="hint"></div>
    <div class="select-input-field__input-wrapper">
      <Icon class="select-input-field__icon" icon="arrow-down" />
      <input
        class="select-input-field__input"
        :aria-label="label"
        autocomplete="off"
        :disabled="disabled"
        :inputmode="inputmode"
        :max="max"
        :min="min"
        :maxlength="maxlength"
        :minlength="minlength"
        :name="name"
        :pattern="pattern"
        :placeholder="placeholder"
        :readonly="readonly || !canFilter"
        ref="inputRef"
        :tabindex="tabindex"
        :type="type"
        :value="localValue"
        @input="onInput"
        @keydown="onKeyDown"
        @paste="onInput"
        @focusin="onFocus"
        @blur="onBlur"
      />
      <div class="select-input-field__options" v-show="isActive">
        <div
          class="select-input-field__options--scrollable-wrapper"
          :style="`max-height: ${availableVerticalSpaceForDropDown}px`"
          @mousedown.prevent="mousedown = true"
          @mouseleave="onScrollMouseout"
        >
          <ul class="select-input-field__options__list select-input-field__options__list--suggestions">
            <li
              ref="suggestion"
              class="select-input-field__options__list__item"
              :class="{ 'select-input-field__options__list__item--focus': activeListIndex === index }"
              v-for="(option, index) in suggestedOptions"
              :key="index + option.key"
              :data-value="option.key"
              :data-index="index"
              v-text="option.label"
              @click="onItemClick(option)"
              @mouseover="onMouseOver"
              @mouseout="onMouseOut"
            ></li>
          </ul>
          <ul class="select-input-field__options__list">
            <li
              class="select-input-field__options__list__item"
              :class="{
                'select-input-field__options__list__item--active': option.label === modelValue,
                'select-input-field__options__list__item--focus': activeListIndex === index
              }"
              ref="suggestion"
              v-for="(option, index) in localOptions"
              :key="index"
              :data-value="option.key"
              :data-index="index"
              v-text="option.label"
              @click="onItemClick(option)"
              @mouseover="onMouseOver"
              @mouseout="onMouseOut"
            ></li>
          </ul>
        </div>
      </div>
      <LoadingBar class="iw-input-field__loading" v-if="loading" :loading="loading" :transparent="true" />
    </div>
    <transition name="maxH">
      <div class="iw-input-field__validation-message" v-if="errorMessage" v-html="errorMessage"></div>
    </transition>
  </div>
</template>

<script>
import { reactive, ref, computed, nextTick, onBeforeMount, onMounted, watch, onUpdated, onBeforeUnmount } from "vue";
import _ from "lodash";
import C from "@/assets/constants";
import Icon from "./Icon.vue";
import LoadingBar from "./LoadingBar.vue";

export default {
  name: "SelectInput",
  components: {
    Icon,
    LoadingBar
  },
  props: {
    disabled: { type: Boolean, default: false },
    byline: { type: String, default: null },
    canFilter: { type: Boolean, default: false },
    color: { type: String, default: "white" },
    forceinput: { type: [RegExp, String], default: null },
    hint: { type: String, default: null },
    icon: { type: String, default: null },
    iconPosition: { type: String, default: "left" },
    inputmode: { type: String, default: "text" },
    kind: { type: String, default: "selector" },
    label: { type: String, default: null },
    loading: { type: Boolean, default: false },
    max: { type: [Number, Date, String], default: null },
    min: { type: [Number, Date, String], default: null },
    maxlength: { type: [Number, String], default: null },
    minlength: { type: [Number, String], default: null },
    modelValue: { type: [String, Number, Boolean], default: "" },
    name: { type: String, default: null },
    options: { type: Array, default: () => [] },
    pattern: { type: [String, RegExp], default: null },
    placeholder: { type: String, default: null },
    prefix: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    suffix: { type: String, default: null },
    tabindex: { type: Number, default: null },
    type: { type: String, default: "text" },
    validation: { type: Function, default: () => true },
    validation_message: { type: [String, Object], default: null }
  },
  emits: ["blur", "focus", "keydown", "input", "change", "update:modelValue", "valid"],
  setup(props, context) {
    const isActive = ref(false);
    const isChanged = ref(false);
    const isTouched = ref(false);
    const isValid = ref(true);
    const hadValueSelected = ref(false);
    const keyEntered = ref(false);
    const inputRef = ref(null);
    const suggestion = ref();
    const localValue = ref(null);
    const formattedValue = ref("");
    const errorMessage = ref("");
    const lastKeyDown = reactive({});
    const activeListIndex = ref(null);
    const mousedown = ref(null);
    const availableVerticalSpaceForDropDown = ref(null);

    const focus = () => {
      inputRef.value.focus();
    };

    const blur = () => {
      inputRef.value.blur();
    };

    const selectContent = () => {
      inputRef.value.setSelectionRange(0, props.modelValue.length);
    };

    const selectOption = (option, event) => {
      isTouched.value = true;
      if (event && event.keyCode === C.SPACEBAR_KEYCODE) {
        context.emit("update:modelValue", option.key);
      }
      // We need to check the mouse position because the click event is fired even on keyboard events
      // for radio buttons as per the browser spec. https://github.com/facebook/react/issues/7407
      if (
        event &&
        event.type === C.EVENT_TYPE_CLICK &&
        event.clientX !== 0 &&
        event.offsetX !== 0 &&
        event.clientY !== 0 &&
        event.offsetY !== 0
      ) {
        context.emit("update:modelValue", option.key);
        nextTick(() => {
          validate(props.modelValue);
        });
      }
    };

    const validate = value => {
      let valid = props.validation ? props.validation(value) : true;
      isValid.value = !isTouched.value || valid === true;
      if (isValid.value) {
        errorMessage.value = "";
      } else {
        if (valid === false) {
          if (props.validation_message) {
            errorMessage.value = props.validation_message;
          } else {
            errorMessage.value = "";
          }
        } else {
          errorMessage.value = valid;
        }
      }
      context.emit("valid", isValid.value);
    };

    const onKeyPress = event => {
      const activeElement = suggestion.value[activeListIndex.value];
      switch (event.keyCode) {
        case C.KEYCODE.ENTER:
          event.preventDefault();
          triggerEnterAction(activeElement);
          break;
        case C.KEYCODE.DOWN:
          event.preventDefault();
          triggerDownAction();
          break;
        case C.KEYCODE.UP:
          event.preventDefault();
          triggerUpAction();
          break;
        default:
          keyEntered.value = true;
          break;
      }
    };

    const onKeyDown = event => {
      lastKeyDown.target = {
        value: _.get(event, "target.value"),
        selectionStart: _.get(event, "target.selectionStart"),
        selectionEnd: _.get(event, "target.selectionEnd")
      };
      context.emit("keydown", event);
    };

    const onMouseOver = event => {
      activeListIndex.value = event.target.getAttribute("data-index");
    };

    const onMouseOut = () => {
      activeListIndex.value = null;
    };

    const onScrollMouseout = () => {
      mousedown.value = false;
    };

    const onInput = event => {
      if (event.keyCode == C.KEYCODE.TAB || event.keyCode == C.KEYCODE.SHIFT) return;
      isChanged.value = true;
      if (event.keyCode == C.KEYCODE.ENTER && event.key == "Enter") {
        isTouched.value = true;
      }
      if (props.forceinput) {
        let match = event.target.value.match(props.forceinput) || !_.get(event, "target.value.length");
        if (!match || match.input !== match[0]) {
          event.target.value = _.get(lastKeyDown, "target.value") || "";

          if (_.lowerCase(event.target.type) in C.InputTypeForSelectionAttr) {
            event.target.selectionStart = _.get(lastKeyDown, "target.selectionStart");
            event.target.selectionEnd = _.get(lastKeyDown, "target.selectionEnd");
          }
        }
      }

      let optionLeft = computedOptions.value.filter(option => {
        return option.label.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
      });
      if (optionLeft.length < 1) {
        localValue.value = _.get(this, "lastKeyDown.target.value") || "";
      }
      hadValueSelected.value = false;
      context.emit("update:modelValue", event.target.value);
      nextTick(() => {
        validate(props.modelValue);
      });
    };

    const onFocus = event => {
      isActive.value = true;
      context.emit("focus", event);
    };

    const onBlur = event => {
      if (mousedown.value == true) {
        mousedown.value = false;
      } else {
        keyEntered.value = false;
        isActive.value = false;
        isTouched.value = true;

        if (_.isNumber(activeListIndex.value)) {
          itemSelected(localOptions.value[activeListIndex.value]);
        }
        if (!localValue.value || localOptions.value.length == 0) {
          clearInput();
        }

        nextTick(() => {
          validate(props.modelValue);
        });
        context.emit("blur", event);
      }
    };

    const onItemClick = item => {
      itemSelected(item);
      delayedBlur();
    };

    const delayedBlur = () => {
      activeListIndex.value = null;
      setTimeout(() => blur());
    };

    const itemSelected = option => {
      if (option) {
        context.emit("change", option.key);
        context.emit("input", option.key);
        context.emit("update:modelValue", option.key);
        isTouched.value = true;
        isActive.value = false;
        localValue.value = option.label;
        hadValueSelected.value = true;
        nextTick(() => {
          validate(props.modelValue);
        });
      }
    };

    const clearInput = () => {
      localValue.value = "";
      hadValueSelected.value = false;
      activeListIndex.value = null;
      context.emit("change", localValue.value);
      context.emit("input", localValue.value);
      context.emit("update:modelValue", localValue.value);
    };

    const triggerEnterAction = activeElement => {
      if (activeElement === inputRef.value && isValid.value && !hadValueSelected.value) {
        itemSelected(localOptions.value[0]);
      } else {
        const matchedElementIndex = suggestion.value.indexOf(activeElement);
        if (matchedElementIndex !== -1) {
          const val = activeElement.dataset.value;
          const option = localOptions.value.find(element => element.value == val);
          itemSelected(option);
        }
      }
      delayedBlur();
    };

    const triggerDownAction = () => {
      if (activeListIndex.value === null || activeListIndex.value < suggestion.value.length - 1) {
        activeListIndex.value = activeListIndex.value === null ? 0 : activeListIndex.value + 1;
      }
    };

    const triggerUpAction = () => {
      if (activeListIndex.value === null || activeListIndex.value > 0) {
        activeListIndex.value =
          activeListIndex.value === null ? suggestion.value.length - 1 : activeListIndex.value - 1;
      }
    };

    const getName = value => {
      let option = localOptions.value.find(option => option.key == value);
      return option ? option.label : "";
    };

    const onWindowResize = () => {
      nextTick(() => {
        availableVerticalSpaceForDropDown.value = Math.max(
          48,
          Math.min(384, Math.floor(document.body.clientHeight - 16 - inputRef.value.getBoundingClientRect().bottom))
        );
      });
    };

    //COMP
    const computedOptions = computed(() => {
      let options = props.options;
      if (typeof props.options === "function") options = props.options.call(context);

      return options.map(option => {
        return {
          ...option,
          name: option.label
        };
      });
    });

    const localOptions = computed(() => {
      if (!props.modelValue || !keyEntered.value) return computedOptions.value;
      return computedOptions.value.filter(option => {
        return option.label.toLowerCase().indexOf(props.modelValue.toLowerCase()) > -1;
      });
    });

    const suggestedOptions = computed(() => {
      return localOptions.value.filter(option => option.suggested);
    });

    const isRequired = computed(() => _.isFunction(props.validation) && !props.validation.call("", context));

    const classObject = computed(() => {
      return {
        ["select-input-field--type-" + props.type]: !!props.type,
        [`select-input-field--kind-${props.kind}`]: !!props.kind,
        "select-input-field--has-icon": props.icon,
        "select-input-field--has-prefix": props.prefix,
        "select-input-field--has-suffix": props.suffix,
        ["select-input-field--color-" + props.color]: !!props.color,
        "select-input-field--icon-left": props.iconPosition === "left",
        "select-input-field--active": isActive.value === true,
        "select-input-field--is-require": !!isRequired.value,
        "select-input-field--error": !isValid.value,
        "select-input-field--readonly": props.readonly,
        "select-input-field--disabled": !!props.disabled
      };
    });

    watch(
      () => props.modelValue,
      newValue => {
        localValue.value = getName(newValue);
      }
    );
    onBeforeMount(() => {
      if (computedOptions.value.length == 1) {
        itemSelected(computedOptions.value[0]);
      } else {
        localValue.value = getName(props.modelValue);
      }
    });
    onMounted(() => {
      onWindowResize();
      window.addEventListener("resize", onWindowResize);
    });

    onUpdated(() => {
      onWindowResize();
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", onWindowResize);
    });

    return {
      isActive,
      isChanged,
      isTouched,
      isValid,
      inputRef,
      suggestion,
      activeListIndex,
      localValue,
      formattedValue,
      availableVerticalSpaceForDropDown,
      errorMessage,
      lastKeyDown,

      focus,
      selectContent,
      selectOption,
      validate,
      onKeyPress,
      onKeyDown,
      onMouseOver,
      onMouseOut,
      onScrollMouseout,

      onInput,
      onFocus,
      onBlur,
      onItemClick,

      computedOptions,
      localOptions,
      suggestedOptions,
      isRequired,
      classObject
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/app.scss";

.select-input-field {
  width: 100%;
  position: relative;

  &__label {
    display: block;
    margin-bottom: addSpace(1);
    text-align: left;
  }

  &__hint {
    margin: addSpace(1) 0;
    color: $color-gray;
  }

  &__input-wrapper {
    position: relative;
  }

  &__icon {
    @include transition();
    position: absolute;
    width: addSpace(4);
    height: addSpace(4);
    top: 50%;
    right: addSpace(2);
    transform: translateY(-50%);
    pointer-events: none;
  }

  &__options {
    position: absolute;
    z-index: z("tooltip");
    background: $color-white;
    top: calc(100% - 1px);
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    width: 100%;
    @include standardShadow;

    &--scrollable-wrapper {
      width: 100%;
      padding: 0;
      min-height: pxToRem(48);
      max-height: pxToRem(384);
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }

    &__list {
      &--suggestions {
        border-bottom: 1px solid $color-light-gray;
        padding-bottom: addSpace(1);
        margin-bottom: addSpace(1);

        &:empty {
          display: none;
        }
      }

      &__item {
        cursor: pointer;
        height: auto;
        display: flex;
        align-items: center;
        outline: none;
        position: relative;
        padding: addSpace(1) addSpace(2);

        &:hover,
        &--focus,
        &--active {
          background-color: $color-primary;
        }

        span {
          position: relative;
          z-index: z("front");
        }
      }
    }
  }

  &__input {
    @include transition();
    @include standardShadow;
    display: block;
    width: 100%;
    font-size: $text-note;
    color: $color-blue;
    cursor: pointer;
    padding: addSpace(2) addSpace(4);
    border-radius: 1.25em;
    border: 0;
    outline: 0;
    outline-style: none;
    -webkit-appearance: none;

    &::placeholder {
      color: $color-dark-gray;
    }
    &:-ms-input-placeholder {
      color: $color-dark-gray;
    }
  }

  input[disabled] {
    -webkit-text-fill-color: $color-dark-gray;
    opacity: 1; /* required on iOS */
  }

  &--active {
    .select-input-field__input {
      cursor: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .select-input-field__icon {
      transform: rotate(180deg) translateY(50%);
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: addSpace(1) addSpace(2);
  }

  &--success {
    .select-input-field__input {
      color: $color-success;
      border-color: $color-success;
    }
  }

  &--error {
    .select-input-field__input {
      color: $color-error;
      border-color: $color-error;
    }

    .select-input-field__icon {
      color: $color-error;
    }
  }

  &--disabled {
    pointer-events: none;

    .select-input-field__icon {
      color: $color-dark-gray;
    }

    .select-input-field__input {
      color: $color-dark-gray;
    }
  }

  &--readonly {
    pointer-events: none;
  }
}
</style>
