<template>
  <div class="page">
    <div class="mainSide">
      <div class="main">
        <div class="gallery">
          <div class="infoBox">
            <h1>Market cap</h1>
            <span class="bigValue">
              {{
                Functions.Formatting.getCurrencySymbol() +
                  Functions.Formatting.abbreviateNumber(
                    company.companyData.dataItems.Other.MarketValue1M.value * 1000000
                  )
              }}
            </span>
          </div>
          <div class="infoBox">
            <h1>Enterprise Value</h1>
            <span class="bigValue">
              {{
                Functions.Formatting.getCurrencySymbol() +
                  Functions.Formatting.abbreviateNumber(company.companyData.dataItems.Other.EnterpriseValue.value)
              }}
            </span>
          </div>
          <div class="infoBox">
            <h1>Avg daily trade</h1>
            <span class="bigValue">
              {{
                Functions.Formatting.getCurrencySymbol() +
                  Functions.Formatting.abbreviateNumber(company.companyData.dataItems.Other.Volume1K.value * 1000)
              }}
            </span>
          </div>
          <div class="infoBox">
            <h1>Sector &amp; Industry</h1>
            <div class="industry" v-text="company.registration.industry"></div>
            <div class="sector" v-text="company.registration.sector"></div>
          </div>
        </div>
        <div class="infoBox">
          <h1>Business info</h1>
          <div class="busInfo">
            <div>
              <p v-html="parsedBusinessSummary"></p>
              <p>
                <span class="label">Listed on: </span>
                {{ Functions.Dates.friendlyDateText(company.information.listedOn) }} <br />
                <span class="label">Web: </span>
                <a :href="company.information.web" target="_blank">{{ company.information.web }}</a
                ><br />
                <span v-if="company.information.employees">
                  <span class="label">Employees: </span>
                  {{ company.information.employees }}
                </span>
              </p>
            </div>
            <div>
              <iframe
                class="map"
                width="100%"
                height="300"
                :src="`https://maps.google.com/maps?q=${composedAddress}&t=&z=13&ie=UTF8&iwloc=&output=embed`"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
              <p>
                <span class="label">Head Office: </span>
                <a
                  :href="`https://www.google.com/maps/search/?api=1&query=${fullAddressAndName}&zoom=9`"
                  target="_blank"
                  >{{ composedAddress }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="side">
        <div class="infoBox">
          <h1>52 week high/low</h1>
          <HighLowBar v-bind="highLowProps" />
        </div>

        <div class="infoBox">
          <h1>Consensus view</h1>
          <div class="chart">
            <ConsensusChart :data="consensus" />
          </div>
        </div>

        <div class="infoBox" v-if="company">
          <h1>Target price</h1>
          <TargetPrice :data="company" />
        </div>
      </div>
    </div>

    <div class="infoBox">
      <h1>Board and Executives</h1>
      <div class="officers">
        <a
          class="officer"
          v-for="officer in parsedOfficers"
          :key="JSON.stringify(officer)"
          :href="`https://www.google.com/search?q=${officer.fullName}, ${company.companyName}`"
          target="_blank"
        >
          <Icon icon="person" />
          <span class="name"> {{ officer.fullName }} </span><br />
          <span
            class="titles"
            v-for="(titleCode, i) in officer.titleCodes"
            :key="titleCode"
            :data-tooltip="officer.titles[i]"
            ><span v-if="i > 0">, </span>{{ titleCode }}</span
          >
        </a>
      </div>
    </div>

    <div class="infoBox">
      <h1>Recent news</h1>
      <transition-group name="maxH">
        <DynamicCard
          class="card newsCard"
          v-for="news in company.recentNews"
          :key="`news-${news.title}`"
          :data="news"
          :structure="newsStructure"
          @click="openNews(news)"
        />
      </transition-group>
      <Modal :show="!!currentNews">
        <template v-slot:body v-if="currentNews">
          <div class="newsDetail">
            <div class="title">
              {{ currentNews.title }}
            </div>
            <div class="date">
              {{ Functions.Dates.friendlyDateTimeText(currentNews.announcedOn) }}
            </div>
            <div class="topic" v-for="topic in currentNews.topics" :key="topic.code">
              {{ topic.description }}
            </div>
            <div class="description">
              {{ currentNews.description }}
            </div>
          </div>
        </template>
        <template v-slot:footer v-if="currentNews">
          <Button v-bind="Functions.Presets.Button.Full" text="close" @click="currentNews = null" />
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { ref, computed } from "vue";
import Icon from "@/components/atoms/Icon.vue";
import ConsensusChart from "@/components/atoms/ConsensusChart.vue";
import TargetPrice from "@/components/atoms/TargetPrice.vue";
import HighLowBar from "@/components/atoms/HighLowBar.vue";
import DynamicCard from "@/components/atoms/DynamicCard.vue";
import Button from "@/components/atoms/Button.vue";
import Modal from "@/components/atoms/Modal.vue";
import Functions from "@/functions";
export default {
  name: "Overview",
  props: {
    company: { type: Object, default: () => {} }
  },
  components: {
    Icon,
    ConsensusChart,
    TargetPrice,
    HighLowBar,
    DynamicCard,
    Button,
    Modal
  },
  setup(props) {
    const parsedBusinessSummary = computed(() => {
      let text = _.get(props.company, "information.businessSummary", "");
      text = text.replaceAll(".", ".<br/>");
      return text;
    });

    const composedAddress = computed(() => {
      let street = _.get(props.company, "information.street1", "");
      let city = _.get(props.company, "information.city", "");
      let postCode = _.get(props.company, "information.postCode", "");
      let country = _.get(props.company, "information.country", "");
      return `${street}, ${city}, ${postCode}, ${country}`;
    });

    const fullAddressAndName = computed(() => {
      let name = _.get(props.company, "companyName", "");
      return `${name}, ${composedAddress.value}`;
    });

    const parsedOfficers = computed(() => {
      let officers = _.get(props.company, "officers", []);
      let parsedOfficers = {};
      officers.forEach(officer => {
        let fullName = `${officer.firstName} ${officer.middleName} ${officer.lastName}`;
        fullName = fullName.replace(/\s\s+/g, " ");
        if (!parsedOfficers[fullName]) {
          parsedOfficers[fullName] = {
            ...officer,
            fullName,
            titleCodes: [officer.titleCode],
            titles: [officer.title]
          };
        } else {
          parsedOfficers[fullName].titleCodes.push(officer.titleCode);
          parsedOfficers[fullName].titles.push(officer.title);
        }
      });
      return parsedOfficers;
    });

    const highLowProps = computed(() => {
      return {
        high: _.get(props.company, "companyData.dataItems.Other.['price.high.1Y']", null),
        low: _.get(props.company, "companyData.dataItems.Other.['price.low.1Y']", null),
        current: _.get(props.company, `companyData.priceChange['price.change.1D']`, null)
      };
    });

    const consensus = computed(() => {
      let financials = _.get(props.company, "financials.dataItems.Recommendation", {});
      return financials;
    });

    const newsStructure = {
      columns: [
        {
          name: "codeName",
          class: "align-left",
          content: {
            value: data => {
              return `<span>${
                data.title
              }</span><br/><em style="font-size: 0.85em; letter-spacing: 1px;">${Functions.Dates.friendlyDateTimeText(
                data.announcedOn
              )}</em>`;
            }
          },
          grid: "1fr"
        },
        {
          name: "navigate",
          content: {
            component: Icon,
            value: () => {
              return {
                class: "navigate common-color-blue",
                icon: "arrow-right"
              };
            }
          },
          grid: "50px"
        }
      ]
    };

    const currentNews = ref(null);
    const openNews = news => {
      currentNews.value = news;
    };

    return {
      Functions,
      parsedBusinessSummary,
      composedAddress,
      fullAddressAndName,
      parsedOfficers,
      highLowProps,
      consensus,
      newsStructure,
      currentNews,
      openNews
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  padding-bottom: addSpace(8);

  .mainSide {
    @include flexCentered;
    align-items: flex-start;
    gap: addSpace(1);

    .main {
      flex-basis: 66%;

      .busInfo {
        @include flexCentered;
        align-items: flex-start;
        gap: addSpace(4);
        line-height: 1.2;

        .label {
          color: $color-dark-gray;
        }
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        & > * {
          flex-basis: 50%;
        }
        @include mq($to: tablet) {
          flex-direction: column;
        }
      }
    }
    .side {
      flex-basis: 33%;
    }
    @include mq($to: phablet) {
      flex-direction: column-reverse;
      .main,
      .side {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }

  .gallery {
    @include flexCentered;
    flex-wrap: wrap;
    align-items: stretch;
    gap: addSpace(1);
    & > * {
      flex-basis: 20%;
      flex-grow: 1;
    }
    @include mq($to: desktop) {
      & > * {
        flex-basis: 40%;
      }
    }
    @include mq($to: small) {
      & > * {
        flex-basis: 100%;
      }
    }
  }

  .bigValue {
    font-size: 2rem;
    font-weight: 100;
  }

  .industry {
  }
  .sector {
    font-size: 0.85em;
    font-style: italic;
    letter-spacing: 0.5px;
    color: $color-dark-gray;
  }

  .map {
    @include edge;
    background: $color-off-white;
  }

  .officers {
    display: grid;
    grid-gap: addSpace(1);
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    .officer {
      @include flexCentered(column);
      @include transition;
      align-items: flex-start;
      justify-content: flex-start;
      flex-grow: 1;
      flex-basis: calc(50% - #{addSpace(1)});
      position: relative;
      padding: addSpace(4) addSpace(2);
      border-left: 1px solid $color-blue;
      display: inline-block;
      text-decoration: none;

      &:hover {
        border-left-width: 10px;
        border-top-left-radius: 10px;
        text-decoration: underline;
      }

      .name {
        font-weight: 600;
      }

      .titles {
        font-size: 0.75em;
        font-style: italic;
      }

      .icon {
        position: absolute;
        width: 4em;
        height: 4em;
        left: 0;
        top: 0;
        opacity: 0.05;
        pointer-events: none;
      }
    }
  }

  .newsCard {
    :deep(.dynamicCard) {
      padding: addSpace(2) 0;
      background: lighten($color-off-white, 3%);
    }
    :deep(.codeName) {
      padding: 0 addSpace(3);
    }
  }

  :deep(.modal-wrapper) {
    padding: addSpace(4);
  }
  .newsDetail {
    padding: addSpace(4);
    .title {
      font-size: 1.75em;
      font-weight: 400;
    }
    .date {
      font-size: 0.75em;
      font-style: italic;
      letter-spacing: 1px;
      margin: addSpace(1) 0 addSpace(2);
    }
    .topic {
      display: inline-block;
      margin: addSpace(1);
      padding: addSpace(1) addSpace(2);
      font-size: 0.8em;
      border-radius: 1em;
      background: $color-white;
    }
    .description {
      margin: addSpace(4) 0;
      line-height: 1.3;
    }
  }
}
</style>
