<template>
  <div class="toggle-input-field" :class="classObject">
    <label class="label" v-if="label" v-t="label"></label>
    <div class="hint" v-if="hint" v-t="hint"></div>
    <div class="wrapper" @click="$emit('update:modelValue', !modelValue)">
      <div class="thumb"></div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "ToggleInput",
  props: {
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: null },
    id: { type: String, default: null },
    label: { type: String, default: null },
    modelValue: { type: [String, Boolean], default: false }
  },
  emits: ["update:modelValue"],
  setup(props) {
    const classObject = computed(() => {
      return {
        "toggle-input-field--is-true": !!props.modelValue
      };
    });
    return {
      classObject
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.toggle-input-field {
  margin-top: addSpace(2);
  margin-bottom: addSpace(2);

  .label {
    display: block;
    margin-bottom: addSpace();
    text-align: left;
    font-size: $text-small-lead;
  }

  .hint {
    margin-left: addSpace(4);
    margin-bottom: addSpace();
  }

  .wrapper {
    @include transition();
    @include standardShadow;
    position: relative;
    width: 4em;
    height: 2.5em;
    cursor: pointer;
    border-radius: 1.25em;
    background: $color-light-gray;

    .thumb {
      @include transition();
      position: absolute;
      display: block;
      top: 0.25em;
      left: 0.25em;
      width: 2em;
      height: 2em;
      border-radius: 1.25em;
      background: $color-white;
    }
  }

  &--is-true {
    .wrapper {
      background: $color-blue;
      .thumb {
        left: 1.75em;
      }
    }
  }
}
</style>
