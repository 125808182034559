<template>
  <div class="consensusChart">
    <div class="total"><Icon icon="profile" /> {{ sum }} total analysts</div>
    <div class="bars">
      <div class="bar" :class="bar.key" v-for="bar in items" :key="bar.key">
        <div class="fill" :style="cssVarsFill(bar)"></div>
        <div class="val" :class="{ zero: bar.value == 0 }" v-text="bar.value"></div>
      </div>
    </div>
    <div class="base"></div>
    <div class="axis">
      <div>Strong Sell</div>
      <div>Hold</div>
      <div>Strong Buy</div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { computed } from "vue";
import Icon from "./Icon.vue";
export default {
  name: "ConsensusChart",
  components: {
    Icon
  },
  props: {
    data: { type: Object, default: () => {} }
  },
  setup(props) {
    const itemList = ["StrongSellCount", "SellCount", "HoldCount", "BuyCount", "StrongBuyCount"];

    const sum = computed(() => {
      return itemList.reduce((tot, item) => {
        return tot + _.get(props.data, `[${item}].value`, 0);
      }, 0);
    });

    const max = computed(() => {
      return itemList.reduce((max, item) => {
        return Math.max(max, _.get(props.data, `[${item}].value`, 0));
      }, 0);
    });

    const items = computed(() => {
      let items = itemList.map(item => {
        return {
          key: item,
          value: _.get(props.data, `[${item}].value`, 0)
        };
      });
      return items;
    });

    const cssVarsFill = bar => {
      return {
        "--size": (bar.value / max.value) * 100 + "%"
      };
    };

    return {
      sum,
      items,
      cssVarsFill
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.consensusChart {
  @include flexCentered(column);
  margin: addSpace(2) 0;

  .total {
    margin: addSpace(-3) 0 addSpace(3) auto;
    color: $color-off-black;
    font-size: 0.8em;
  }

  .bars {
    @include flexCentered;
    width: 100%;
    height: 75px;
    gap: addSpace(1);

    .bar {
      @include flexCentered(column);
      position: relative;
      flex-grow: 1;
      flex-basis: calc(20% - #{addSpace(1)});
      height: 100%;
      background: $color-off-white;
      border-top-left-radius: 10px;
      overflow: hidden;

      .fill {
        @include transition;
        width: 100%;
        height: var(--size);
        margin-top: auto;
        background-color: $color-blue;
        background-image: linear-gradient(
          45deg,
          rgba($color-white, 0.2) 16.67%,
          transparent 16.67%,
          transparent 50%,
          rgba($color-white, 0.2) 50%,
          rgba($color-white, 0.2) 66.67%,
          transparent 66.67%,
          transparent 100%
        );
        background-size: 6px 6px;
      }

      .val {
        position: absolute;
        color: $color-blue;
        font-weight: 400;
        bottom: addSpace(1);

        &.zero {
          color: $color-white;
        }
      }

      &.StrongSellCount .fill {
        background-color: $color-error;
      }
      &.SellCount .fill {
        background-color: mix($color-warning, $color-error, 60);
      }
      &.HoldCount .fill {
        background-color: $color-warning;
      }
      &.BuyCount .fill {
        background-color: mix($color-success, $color-warning, 60);
      }
      &.StrongBuyCount .fill {
        background-color: $color-success;
      }
    }
  }

  .base {
    width: 100%;
    margin: addSpace(1) 0;
    height: addSpace(1);
    border: 1px solid $color-light-gray;
    border-bottom: none;
  }

  .axis {
    @include flexCentered;
    width: 100%;
    font-size: 0.75em;

    & > div:first-child + div {
      margin: 0 auto;
    }
  }
}
</style>
