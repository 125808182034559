<template>
  <div class="highLowBar">
    <div class="low" v-if="low">
      <div class="value" v-text="Functions.Formatting.currency(low.value)"></div>
      <div class="date" v-text="formattedDate(low.valueS)"></div>
    </div>
    <div class="bars" ref="barRef">
      <div
        class="bar"
        :class="{ selected: bar == selectedBar }"
        v-for="bar in bars"
        :key="bar"
        :data-current="Functions.Formatting.currency(current.price)"
      ></div>
    </div>
    <div class="high" v-if="high">
      <div class="value" v-text="Functions.Formatting.currency(high.value)"></div>
      <div class="date" v-text="formattedDate(high.valueS)"></div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import _ from "lodash";
import Constants from "@/assets/constants";
import Functions from "@/functions";
export default {
  name: "HighLowBar",
  props: {
    high: { type: Object, default: () => {} },
    low: { type: Object, default: () => {} },
    current: { type: Object, default: () => {} }
  },
  setup(props) {
    const barRef = ref(null);
    const barWidth = ref(0);

    const formattedDate = date => {
      let dateParts = date.split("/");
      let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      return Functions.Dates.dateText(dateObject, Constants.DATE_FORMAT.SHORT_DAY_OF);
    };

    const getBarWidth = () => {
      barWidth.value = _.get(barRef.value, "clientWidth", 0);
    };

    const bars = computed(() => {
      return Math.round(barWidth.value / 8);
    });

    const gap = computed(() => {
      let h = Math.round((_.get(props, "high.value", 0) + Number.EPSILON) * 100) / 100;
      let l = Math.round((_.get(props, "low.value", 0) + Number.EPSILON) * 100) / 100;
      let c = Math.round((_.get(props, "current.price", 0) + Number.EPSILON) * 100) / 100;
      let t = h - l;
      let b = c - l;
      return (100 / t) * b;
    });

    const selectedBar = computed(() => {
      return Math.round((bars.value / 100) * gap.value);
    });

    onMounted(() => {
      window.addEventListener("resize", getBarWidth);
      getBarWidth();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", getBarWidth);
    });

    return {
      Functions,
      formattedDate,
      barRef,
      barWidth,
      bars,
      gap,
      selectedBar
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.highLowBar {
  @include flexCentered;
  margin: addSpace(2) auto;

  .low {
    @include flexCentered(column);
    align-items: flex-start;
    padding: addSpace(2);
    .value {
      color: $color-error;
      font-weight: 400;
    }
  }
  .high {
    @include flexCentered(column);
    padding: addSpace(2);
    align-items: flex-end;
    .value {
      color: $color-success;
      font-weight: 400;
    }
  }
  .bars {
    @include flexCentered;
    justify-content: space-between;
    flex-grow: 1;

    .bar {
      position: relative;
      width: 4px;
      height: 30px;
      background: $color-light-gray;
      border-top-left-radius: 5px;

      &.selected {
        height: 40px;
        background: $color-blue;

        &:after {
          position: absolute;
          top: 115%;
          display: block;
          content: attr(data-current);
          color: $color-blue;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
