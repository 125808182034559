<template>
  <div class="page">
    <div class="infoBox">
      <h1>Income statement</h1>
      <FinancialTable :structure="incomeStatement" />
    </div>

    <div class="infoBox">
      <h1>Balance Sheet</h1>
      <FinancialTable :structure="balanceSheet" />
    </div>

    <div class="infoBox">
      <h1>Profitability Ratios</h1>
      <FinancialTable :structure="profitabilityRatios" />
    </div>

    <div class="infoBox">
      <h1>Dividends</h1>
      <FinancialTable :structure="dividends" />
    </div>

    <div class="infoBox">
      <h1>Valuation</h1>
      <FinancialTable :structure="valuation" />
    </div>

    <div class="infoBox">
      <h1>Cash flow</h1>
      <FinancialTable :structure="cashFlow" />
    </div>

    <div class="infoBox">
      <h1>Margins</h1>
      <FinancialTable :structure="margins" />
    </div>

    <div class="infoBox">
      <h1>Financial Strength</h1>
      <FinancialTable :structure="financialStrength" />
    </div>

    <div class="infoBox">
      <h1>Fragmentation</h1>
      <div class="defragText">
        <strong>{{ Functions.Formatting.abbreviateNumber(company.information.shares) }}</strong> shares for
        <strong>{{ Functions.Formatting.abbreviateNumber(company.information.shareholders) }}</strong> shareholders
      </div>
      <div class="defrags">
        <div class="defrag" v-for="d in defrag" :key="d"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { computed } from "vue";
import FinancialTable from "@/components/atoms/FinancialTable.vue";
import Functions from "@/functions";
export default {
  name: "Financial",
  props: {
    company: { type: Object, default: () => {} }
  },
  components: {
    FinancialTable
  },
  setup(props) {
    const incomeStatement = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "Revenue",
          current: _.get(financials, `Current['SalesRevenue']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        },
        {
          label: "EBITDA",
          current: _.get(financials, `Current['EBITDA']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        },
        {
          label: "EBIT",
          current: _.get(financials, `Current['EBIT']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        },
        {
          label: "Net Profit after tax",
          current: _.get(financials, `Current['Net Profit after tax']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        }
      ];
    });

    const balanceSheet = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "Total Assets",
          current: _.get(financials, `Current['Total Assets']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        },
        {
          label: "Total Debt",
          current: _.get(financials, `Current['Total Debt']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        },
        {
          label: "Total Equity",
          current: _.get(financials, `Current['ShareholderEquity']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        },
        {
          label: "Cash balance",
          current: _.get(financials, `Current['Cash balance']`, {}),
          forecast: null,
          formatting: Functions.Formatting.bigCurrency
        }
      ];
    });

    const profitabilityRatios = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "Earnings per share (EPS)",
          current: _.get(financials, `Current['EPS12M']`, {}),
          forecast: _.get(financials, `Forecast['EPSForecastFY1']`, {}),
          formatting: Functions.Formatting.currency
        },
        {
          label: "Return on Equity (ROE)",
          current: null,
          forecast: _.get(financials, `Current['ROEFD12PC']`, {}),
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Return on Invst Capital (ROI)",
          current: _.get(financials, `Current['ROIPC']`, {}),
          forecast: null,
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Return on Assets (ROA)",
          current: null,
          forecast: _.get(financials, `Forecast['ROAFD12PC']`, {}),
          formatting: Functions.Formatting.percentage
        }
      ];
    });

    const dividends = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "Dividend per share",
          current: _.get(financials, `Current['DPSLastReported']`, {}),
          forecast: _.get(financials, `Forecast['DPSForecastFY1']`, {}),
          formatting: Functions.Formatting.currency
        },
        {
          label: "Dividend Yield",
          current: _.get(financials, `Current['Dividend Yield']`, {}),
          forecast: _.get(financials, `Forecast['Dividend Yield']`, {}),
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Gross Div Yield",
          current: _.get(financials, `Current['Gross Dividend Yield']`, {}),
          forecast: _.get(financials, `Forecast['Gross Dividend Yield']`, {}),
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Payout Ratio",
          current: _.get(financials, `Current['Payout Ratio']`, {}),
          forecast: _.get(financials, `Forecast['Payout Ratio']`, {}),
          formatting: Functions.Formatting.percentage
        }
      ];
    });

    const valuation = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "Price to Earnings",
          current: _.get(financials, `Current['PE']`, {}),
          forecast: _.get(financials, `Forecast['PEForecastFY1']`, {}),
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "Price to Sales",
          current: _.get(financials, `Current['Price to Sales']`, {}),
          forecast: _.get(financials, `Forecast['Price to Sales']`, {}),
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "Price to Cashflow",
          current: _.get(financials, `Current['Price to Cashflow']`, {}),
          forecast: _.get(financials, `Forecast['Price to Cashflow']`, {}),
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "Price to book vale",
          current: null,
          forecast: null,
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "EV/FCF",
          current: _.get(financials, `Current['EV/FCF']`, {}),
          forecast: null,
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "EV/Sales",
          current: null,
          forecast: null,
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "EV/EBITDA",
          current: null,
          forecast: null,
          formatting: Functions.Formatting.restrictDecimals
        }
      ];
    });

    const cashFlow = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "Free cash flow ps",
          current: null,
          forecast: _.get(financials, `Forecast['FreeCashFlowPerShareMeanFY1']`, {}),
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "Cash flow ps",
          current: null,
          forecast: _.get(financials, `Forecast['CashFlowPerShareMeanFY1']`, {}),
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "Cash conversion",
          current: _.get(financials, `Current['Cash Conversion Ratio']`, {}),
          forecast: null,
          formatting: Functions.Formatting.restrictDecimals
        }
      ];
    });

    const margins = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "Gross Margin",
          current: _.get(financials, `Current['Gross Margin']`, {}),
          forecast: null,
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Operating Margin",
          current: _.get(financials, `Current['OperatingProfitMarginPC']`, {}),
          forecast: null,
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Net Profit Margin",
          current: _.get(financials, `Current['NetProfitMarginPC']`, {}),
          forecast: null,
          formatting: Functions.Formatting.percentage
        }
      ];
    });

    const financialStrength = computed(() => {
      let financials = _.get(props.company, "financials.dataItems", "");
      return [
        {
          label: "LT Debt to Equity",
          current: null,
          forecast: null,
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Net Debt to Equity",
          current: _.get(financials, `Current['OperatingProfitMarginPC']`, {}),
          forecast: null,
          formatting: Functions.Formatting.percentage
        },
        {
          label: "Interest Coverage",
          current: _.get(financials, `Current['Interest coverage']`, {}),
          forecast: null,
          formatting: Functions.Formatting.restrictDecimals
        },
        {
          label: "Quick ratio",
          current: _.get(financials, `Current['Quick ratio']`, {}),
          forecast: null,
          formatting: Functions.Formatting.restrictDecimals
        }
      ];
    });

    const defrag = computed(() => {
      return Math.round(
        _.get(props.company, "information.shares", 0) / _.get(props.company, "information.shareholders", 0) / 100
      );
    });

    return {
      Functions,
      incomeStatement,
      balanceSheet,
      profitabilityRatios,
      dividends,
      valuation,
      cashFlow,
      margins,
      financialStrength,
      defrag
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  padding-bottom: addSpace(8);

  .defragText {
    text-align: right;
    strong {
      font-weight: 400;
    }
  }
  .defrags {
    @include edge;
    @include flexCentered;
    margin-top: addSpace(2);
    height: 20px;
    overflow: hidden;
    background: rgba($color-blue, 0.2);

    .defrag {
      width: 100%;
      height: 100%;
      flex-grow: 1;

      &:nth-child(7n) {
        max-width: 3px;
        background: rgba($color-light, 0.2);
      }
      &:nth-child(11n) {
        max-width: 3px;
        background: rgba($color-light, 0.4);
      }
      &:nth-child(13n) {
        max-width: 3px;
        background: rgba($color-light, 0.6);
      }
      &:nth-child(17n) {
        max-width: 3px;
        background: rgba($color-light, 0.8);
      }
    }
  }
}
</style>
