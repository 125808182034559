<template>
  <div class="targetPrice" :class="classObject">
    <div class="overBar">
      <div class="target" :style="cssVarsFill">
        <div class="value" v-text="Functions.Formatting.currency(priceTargetMedian)"></div>
        <div class="label">Target</div>
      </div>
    </div>
    <div class="bars">
      <div class="bar">
        <div class="fill" :style="cssVarsFill"></div>
      </div>
    </div>
    <div class="base"></div>
    <div class="axis">
      <div>{{ Functions.Formatting.currency(priceTargetLow) }}<br />Low</div>
      <div>{{ Functions.Formatting.currency(priceTargetHigh) }}<br />High</div>
    </div>
    <div class="premium">
      <span class="value">
        {{ Functions.Formatting.percentage(Math.abs(premiumPC)) }}
        <span v-text="premiumPC > 0 ? 'premium' : 'discount'"></span>
      </span>
      <span class="from"> to {{ Functions.Formatting.currency(priceAdjusted) }}</span>
    </div>
    <div class="lastUpdated">
      Last updated:
      <span class="value">{{ Functions.Dates.friendlyDateText(lastUpdated) }}</span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { computed } from "vue";
import Functions from "@/functions";
export default {
  name: "TargetPrice",
  props: {
    data: { type: Object, default: () => {} }
  },
  setup(props) {
    const priceTargetLow = computed(() => {
      return (
        Math.round(
          (_.get(props.data, "financials.dataItems.Recommendation.PriceTargetLow.value", 0) + Number.EPSILON) * 100
        ) / 100
      );
    });
    const priceTargetHigh = computed(() => {
      return (
        Math.round(
          (_.get(props.data, "financials.dataItems.Recommendation.PriceTargetHigh.value", 0) + Number.EPSILON) * 100
        ) / 100
      );
    });
    const priceTargetMedian = computed(() => {
      return (
        Math.round(
          (_.get(props.data, "financials.dataItems.Recommendation.PriceTargetMean.value", 0) + Number.EPSILON) * 100
        ) / 100
      );
    });
    const priceAdjusted = computed(() => {
      return (
        Math.round((_.get(props.data, "companyData.priceChange['price.change.1D'].price", 0) + Number.EPSILON) * 100) /
        100
      );
    });
    const premiumPC = computed(() => {
      return _.get(props.data, "financials.dataItems.Recommendation.PremiumPC.value", 0);
    });
    const lastUpdated = computed(() => {
      return _.get(props.data, "financials.dataItems.Recommendation.ConsensusRecommendation.date", 0);
    });

    const trendColor = computed(() => {
      return premiumPC.value >= 0 ? "red" : "green";
    });

    const gapTarget = computed(() => {
      let h = priceTargetHigh.value - priceTargetLow.value;
      if (h == 0) {
        return 50;
      }
      let c = priceTargetMedian.value - priceTargetLow.value;

      return (100 / h) * c;
    });

    const gapAdjusted = computed(() => {
      let h = priceTargetHigh.value - priceTargetLow.value;
      if (h == 0) {
        return 50;
      }
      let c = priceAdjusted.value - priceTargetLow.value;

      return (100 / h) * c;
    });

    const fillWidth = computed(() => {
      return Math.abs(gapTarget.value - gapAdjusted.value);
    });

    const classObject = computed(() => {
      return {
        ["targetPrice--trend-" + trendColor.value]: true
      };
    });

    const cssVarsFill = computed(() => {
      return {
        "--left": Math.min(gapTarget.value, gapAdjusted.value) + "%",
        "--width": fillWidth.value + "%",
        "--targetPc": gapTarget.value + "%"
      };
    });

    return {
      Functions,
      priceTargetLow,
      priceTargetHigh,
      priceTargetMedian,
      priceAdjusted,
      premiumPC,
      lastUpdated,
      trendColor,
      classObject,
      cssVarsFill
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.targetPrice {
  @include flexCentered(column);
  align-items: flex-start;
  margin: addSpace(2) 0;

  .overBar {
    position: relative;
    width: 100%;
    height: 40px;

    .target {
      position: absolute;
      padding: 0 addSpace(1) addSpace(1) addSpace(1);
      margin-bottom: 2px;
      .value {
        color: $color-blue;
        font-size: 1.1em;
        font-weight: 400;
      }
      .label {
        font-size: 0.9em;
      }
    }
  }

  .bars {
    @include flexCentered;
    width: 100%;
    height: 5px;
    gap: addSpace(1);

    .bar {
      position: relative;
      @include flexCentered(column);
      flex-grow: 1;
      width: 100%;
      height: 100%;
      background: $color-off-white;
      border-top-left-radius: 10px;
      overflow: hidden;

      .fill {
        position: absolute;
        left: var(--left);
        width: var(--width);
        height: 100%;
        margin-top: auto;
        background-image: linear-gradient(
          45deg,
          rgba($color-white, 0.2) 16.67%,
          transparent 16.67%,
          transparent 50%,
          rgba($color-white, 0.2) 50%,
          rgba($color-white, 0.2) 66.67%,
          transparent 66.67%,
          transparent 100%
        );
        background-size: 6px 6px;
        border-left: 2px solid $color-white;
        border-right: 2px solid $color-white;
      }
    }
  }

  .base {
    width: 100%;
    margin: addSpace(1) 0;
    height: addSpace(1);
    border: 1px solid $color-light-gray;
    border-bottom: none;
  }

  .axis {
    @include flexCentered;
    justify-content: space-between;
    width: 100%;
    font-size: 0.75em;
  }

  .premium {
    margin-top: addSpace(2);
    width: 100%;
    text-align: center;

    .value {
      font-weight: 400;
    }

    .from {
      color: $color-gray;
      font-weight: 300;
    }
  }

  .lastUpdated {
    margin-bottom: addSpace(2);
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    font-style: italic;
    .value {
      font-weight: 400;
    }
  }

  &--trend-green {
    .fill {
      background-color: $color-blue;
    }
    .target {
      text-align: right;
      border-right: 1px solid $color-light-gray;
      margin-right: 1px;
      right: calc(100% - var(--targetPc));
    }
    .premium .value {
      color: darken($color-blue, 10%);
    }
  }
  &--trend-red {
    .fill {
      background-color: $color-error;
    }
    .target {
      border-left: 1px solid $color-light-gray;
      margin-left: 1px;
      left: var(--targetPc);
    }
    .premium .value {
      color: darken($color-error, 5%);
    }
  }
}
</style>
