<template>
  <div class="page">
    <div class="filters" :class="{ loading: loading }">
      <div class="filter"><span>Timeframe</span><SelectInput v-model="timeframe" :options="timeframeOptions" /></div>
      <div class="filter"><span>Price sensitive only</span><ToggleInput v-model="priceSensitiveOnly" /></div>
    </div>
    <transition-group name="maxH">
      <DynamicCard
        class="card"
        :class="{ loading: loading }"
        v-for="announcement in announcements"
        :key="`announcement-${announcement.documentId}`"
        :data="announcement"
        :structure="announcementsStructure"
        @click="openFile(announcement)"
      />
    </transition-group>
    <transition name="maxH">
      <div v-if="loading && announcements.length == 0">
        <div class="skLoadingCard skLoading"></div>
        <div class="skLoadingCard skLoading"></div>
        <div class="skLoadingCard skLoading"></div>
      </div>
    </transition>
    <transition name="maxH">
      <div class="emptyMessage" v-if="announcements.length == 0 && !loading">
        There are no announcements for this timeframe
      </div>
    </transition>
  </div>
</template>

<script>
import _ from "lodash";
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import DynamicCard from "@/components/atoms/DynamicCard.vue";
import SelectInput from "@/components/atoms/SelectInput.vue";
import ToggleInput from "@/components/atoms/ToggleInput.vue";
import Icon from "@/components/atoms/Icon.vue";
import Functions from "@/functions";
import Constants from "@/assets/constants";
export default {
  name: "Announcements",
  props: {
    company: { type: Object, default: () => {} }
  },
  components: {
    DynamicCard,
    SelectInput,
    ToggleInput
  },
  setup(props) {
    const store = useStore();
    const lsContext = "company/announcements";
    const timeframeOptions = [
      {
        label: "1D",
        key: 1
      },
      {
        label: "1W",
        key: 7
      },
      {
        label: "1M",
        key: 30
      },
      {
        label: "1Y",
        key: 365
      },
      {
        label: "5Y",
        key: 1825
      }
    ];
    const timeframe = ref(timeframeOptions[3].key);
    const priceSensitiveOnly = ref(false);
    const loading = ref(false);

    const filterAnnouncements = _.throttle(
      function() {
        loading.value = true;
        let code = _.get(props.company, "information.companyCode", null);
        let past = new Date();
        past.setDate(past.getDate() - timeframe.value);
        let from = Functions.Dates.dateText(past, Constants.DATE_FORMAT.ISO_EXTENDED);
        store
          .dispatch("Company/announcements", {
            code,
            from,
            priceSensitiveOnly: priceSensitiveOnly.value,
            count: 99
          })
          .finally(() => {
            loading.value = false;
          });
      },
      200,
      { trailing: false }
    );

    const announcements = computed(() => {
      let announcements = _.get(props.company, "announcements", []);
      return announcements;
    });

    const announcementsStructure = {
      columns: [
        {
          name: "title",
          class: "align-left",
          content: {
            value: data => {
              return `<span class="common-color-blue">${data.title}</span>`;
            }
          },
          grid: "1fr"
        },
        {
          name: "price-sensitive",
          content: {
            value: data => {
              return data.priceSensitive ? `<span>⥮</span>` : "";
            }
          },
          grid: "50px"
        },
        {
          name: "date",
          class: "align-left",
          content: {
            value: data => {
              return Functions.Dates.dateText(data.announcedOn);
            }
          },
          grid: "0.5fr"
        },
        {
          name: "navigate",
          content: {
            component: Icon,
            value: data => {
              return {
                class: `navigate common-color-blue loading-${data.loading}`,
                icon: data.loading ? "clessidra" : "arrow-right"
              };
            }
          },
          grid: "50px"
        }
      ]
    };

    const openFile = item => {
      store
        .dispatch("Company/announcementDoc", { code: props.company.companyCode, docId: item.documentId })
        .then(res => {
          Functions.Utils.downloadFile(res.data);
        });
    };

    onMounted(() => {
      timeframe.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "timeframe"
        }) || timeframe.value;
      priceSensitiveOnly.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "priceSensitiveOnly"
        }) || priceSensitiveOnly.value;
      filterAnnouncements();
    });

    watch(
      () => timeframe.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "timeframe",
          value: timeframe.value
        });
        filterAnnouncements();
      }
    );
    watch(
      () => priceSensitiveOnly.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "priceSensitiveOnly",
          value: priceSensitiveOnly.value
        });
        filterAnnouncements();
      }
    );

    return {
      Functions,
      timeframe,
      timeframeOptions,
      priceSensitiveOnly,
      loading,
      filterAnnouncements,
      announcements,
      announcementsStructure,
      openFile
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  padding: addSpace(2);
  margin-bottom: addSpace(8);

  @include mq($to: medium) {
    padding: 0;
  }

  .filters {
    @include flexCentered;
    justify-content: flex-start;
    gap: addSpace(2);
    padding: addSpace(2) 0;
    .filter {
      @include flexCentered;
      span {
        background: rgba($color-white, 0.3);
        border-radius: 99em 0 0 99em;
        padding: addSpace(1) addSpace(4) addSpace(1) addSpace(3);
        margin-right: addSpace(-2);
      }
      :deep(.select-input-field__input) {
        width: 80px;
        font-size: 0.7em;
      }
      :deep(.toggle-input-field) {
        font-size: 0.7em;
      }

      @include mq($to: medium) {
        font-size: 0.8em;
      }
    }
  }

  :deep(.navigate.loading-true) {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading {
    pointer-events: none;
    opacity: 0.5;
  }

  .card {
    :deep(.price-sensitive) span {
      font-size: 1.25em;
      color: $color-white;
      background: $color-error;
      border-radius: 99em;
      padding: addSpace(1) addSpace(1.5);
      position: relative;
      z-index: z("flat");
    }
  }
}
</style>
