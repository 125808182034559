<template>
  <div class="page">
    <div class="infoBox">
      <h1>Markets</h1>
      <MediumWidget
        :symbols="[
          ['All Ordinates', 'ASX:XAO|12M'],
          ['S&P/ASX 200', 'ASX:XJO|12M'],
          ['S&P/ASX Small Ordinaries', 'ASX:XSO|12M'],
          ['S&P/ASX 50', 'ASX:XFL|12M']
        ]"
        :height="280"
      />
    </div>

    <div class="infoBox">
      <h1>Recent updates</h1>
      <Tabs
        class="tabs"
        :options="tabItems"
        :current="$route.query.tab"
        @change="$router.replace({ query: { tab: $event } })"
      />
      <h3>Lux changes</h3>
      {{ luxChanges }}<br /><br />
      <div class="improvements">
        <h3>Improvements</h3>
        <transition name="maxH">
          <DynamicCard class="header" :structure="improvementsStructure" v-if="improvements.length > 0" />
        </transition>
        <transition-group name="maxH">
          <DynamicCard
            class="card"
            :class="{ loading: loading }"
            v-for="holding in improvements"
            :key="`holding-${holding}`"
            :data="holding"
            :structure="improvementsStructure"
            :to="'/company/' + Functions.Utils.cleanCode(holding.company.mainSecurityCode)"
          />
        </transition-group>
      </div>
    </div>

    <Modal :show="showDisclaimer">
      <template v-slot:body>
        <div class="disclaimerBody">
          <h1>Important Information</h1>
          <p>
            This disclaimer is addressed to you and any entity you represent, whether you are viewing this site on your
            own behalf or on behalf of another person (such as your employer). By accessing or using this site, you and
            any entity that you represent, agree to be bound by the below terms.
          </p>
          <p>
            The information in this application is not intended to constitute personal advice. It is for general
            information only.
          </p>
          <p>
            Though we respond to questions, in no way do our responses take into account your individual objectives,
            financial situation or needs. Furthermore, under no circumstances should investment decisions be based
            solely on the information/advice provided on this site. You should assess whether the information is
            appropriate for you and seek professional financial advice before relying on or making investment decisions
            based on the Information. Investment products are subject to risk including the loss of income or capital
            invested. Past performance is not an indicator of future performance.
          </p>
          <p>
            Neither Spotee Pty Ltd ACN 637 623 940, Daylight Financial Group Pty Ltd ACN 633 984 773 (AFSL 521404), its
            directors, employees and representatives (collectively, “Spotee”) warrant the accuracy or completeness of
            the Information. To the extent permitted by law, Spotee disclaims all responsibility and liability for any
            loss or damage of any nature whatsoever which may be suffered by any person directly or indirectly.
          </p>
          <p>
            Read the
            <Button v-bind="Functions.Presets.Button.InlineLink" text="Terms of Use" to="/terms" />
            before proceeding
          </p>

          <CheckboxInput
            v-model="acceptDisclaimer"
            label="I understand this information and I have read and accepted the Terms of Use"
          />
        </div>
      </template>

      <template v-slot:footer>
        <Button v-bind="Functions.Presets.Button.Full" :disabled="!acceptDisclaimer" text="Continue" @click="accept" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Button from "@/components/atoms/Button.vue";
import DynamicCard from "@/components/atoms/DynamicCard.vue";
import Icon from "@/components/atoms/Icon.vue";
import PriceChange from "@/components/atoms/PriceChange.vue";
import CheckboxInput from "@/components/atoms/CheckboxInput.vue";
import MediumWidget from "@/components/atoms/MediumWidget.vue";
import Modal from "@/components/atoms/Modal.vue";
import Tabs from "@/components/atoms/Tabs.vue";
import Functions from "@/functions";
export default {
  name: "Dashboard",
  components: {
    Button,
    DynamicCard,
    CheckboxInput,
    MediumWidget,
    Modal,
    Tabs
  },
  setup() {
    const store = useStore();
    const tabItems = [
      {
        label: "Latest",
        key: "latest"
      },
      {
        label: "In Watchlist",
        key: "watchlist"
      },
      {
        label: "Last viewed",
        key: "last-view"
      }
    ];

    const improvementsStructure = {
      columns: [
        {
          name: "code",
          class: "align-left",
          header: {
            value: "Code"
          },
          content: {
            value: data => {
              return `<strong class="common-color-blue">${Functions.Utils.cleanCode(
                data.company.mainSecurityCode
              )}</strong>`;
            }
          },
          grid: "55px"
        },
        {
          name: "name",
          class: "align-left",
          header: {
            value: "Name"
          },
          content: {
            value: data => {
              return data.company.commonName;
            }
          },
          grid: [
            {
              to: "mobile",
              grid: null
            },
            {
              grid: "1fr"
            }
          ]
        },
        {
          name: "priceChange",
          header: {
            value: "Price change"
          },
          content: {
            component: PriceChange,
            value: data => {
              return {
                percentage: data.priceChange.changePC,
                dollar: data.priceChange.changeDollar
              };
            }
          },
          grid: "0.5fr"
        },
        {
          name: "date",
          class: "align-left",
          content: {
            value: data => {
              return Functions.Dates.dateText(data.priceChange.date);
            }
          },
          grid: "0.5fr"
        },
        {
          name: "navigate",
          content: {
            component: Icon,
            value: () => {
              return {
                class: "navigate common-color-blue",
                icon: "arrow-right"
              };
            }
          },
          grid: "50px"
        }
      ]
    };

    const showDisclaimer = ref(false);
    const acceptDisclaimer = ref(false);

    const disclaimerCheck = () => {
      let today = new Date();
      let backWhen = new Date(store.getters["User/userData"].tcAccepted);
      if (
        (today.getDate() == backWhen.getDate() && store.getters["User/userData"].tcAcceptedDays > 0) ||
        store.getters["User/userData"].tcAcceptedDays > 30
      ) {
        showDisclaimer.value = true;
      }
    };

    const accept = () => {
      store.dispatch("User/acceptTC").finally(() => {
        store.dispatch("User/getCurrent").finally(() => {
          showDisclaimer.value = false;
        });
      });
    };

    const luxChanges = computed(() => {
      return store.getters["LuxScore/luxScores"];
    });

    const improvements = computed(() => {
      return store.getters["Company/improvements"];
    });

    onMounted(() => {
      store.dispatch("Portfolio/getPortfolios");
      store.dispatch("LuxScore/getChanges", { profile: "default", timeframe: "1y" });
      store.dispatch("Company/improvements", { timeframe: "1y" });
      disclaimerCheck();
    });

    return {
      Functions,
      luxChanges,
      improvements,
      tabItems,
      improvementsStructure,
      showDisclaimer,
      acceptDisclaimer,
      accept
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  padding: addSpace(1);
  padding-bottom: addSpace(8);
  @include mq($to: phablet) {
  }

  .contentCard {
    width: 100%;
    @include maxWidth($size-breakpoint-wide);
    @include standardShadow;
    margin: addSpace(2);
    padding: addSpace(2);
    border-radius: 3px;
    background: $color-white;

    &.chart {
      padding: 0;
      border-bottom-right-radius: 30px;
      overflow: hidden;
      background: $color-blue-light;
    }
  }

  .tabs {
    position: relative;
    z-index: z("front");
  }

  .disclaimerBody {
    @include maxWidth($size-breakpoint-phablet);
    padding: addSpace(5) addSpace(5) 0 addSpace(5);
    text-align: justify;
    .checkbox-input-field {
      padding-top: addSpace(5);
      border-top: 1px dashed $color-light-gray;
    }
  }
}
</style>
