<template>
  <div class="page">
    <div class="watchlist">
      <transition name="maxH">
        <DynamicCard class="header" :structure="stockStructure" v-if="holdings.length > 0" />
      </transition>
      <transition-group name="maxH">
        <DynamicCard
          class="card"
          v-for="holding in holdings"
          :key="`holding-${holding.code}`"
          :data="holding"
          :structure="stockStructure"
          :to="'/company/' + Functions.Utils.cleanCode(holding.code)"
        />
      </transition-group>
      <transition name="maxH">
        <div v-if="$store.getters['Portfolio/busy'] && holdings.length == 0">
          <div class="skLoadingCard" :class="{ skLoading: $store.getters['Portfolio/busy'] }"></div>
          <div class="skLoadingCard" :class="{ skLoading: $store.getters['Portfolio/busy'] }"></div>
        </div>
      </transition>
    </div>

    <transition name="maxH">
      <div class="empty" v-if="$store.getters['Portfolio/noHoldings']">
        There are no stocks in your watchlist.
      </div>
    </transition>

    <div class="more">
      To add or remove stocks from your watchlist, go to an individual stock's page and click on the star in the top
      right corner <Icon icon="star" /> =&gt; <Icon icon="star-full" />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Icon from "@/components/atoms/Icon.vue";
import LuxBreakdownInList from "@/components/atoms/LuxBreakdownInList.vue";
import SelectInput from "@/components/atoms/SelectInput.vue";
import PriceChange from "@/components/atoms/PriceChange.vue";
import DynamicCard from "@/components/atoms/DynamicCard.vue";
import HeaderSort from "@/components/atoms/HeaderSort.vue";
import Functions from "@/functions";
import Constants from "@/assets/constants";
export default {
  name: "Watchlist",
  components: {
    Icon,
    DynamicCard
  },
  setup() {
    const store = useStore();
    const lsContext = "watchlist";
    const currentPrice = ref(`companyData.priceChange["price.change.1W"]`);
    const orderBy = ref("code");
    const orderByDirection = ref(Constants.ORDER_DIRECTION.ASC);

    const loadingData = computed(() => {
      return store.getters["Portfolio/busy"] || store.getters["Company/busy"];
    });

    const getData = () => {
      store.dispatch("Portfolio/getPortfolios").then(() => {
        store.getters["Portfolio/allHoldings"].forEach(holding => {
          let cleanCode = Functions.Utils.cleanCode(holding.code);
          store.dispatch("Company/details", { code: cleanCode });
        });
      });
    };

    const holdings = computed(() => {
      let hs = store.getters["Portfolio/portfolios"]
        .reduce((h, portfolio) => {
          h = h.concat(portfolio.holdings);
          return h;
        }, [])
        .sort((a, b) => {
          let aCompare = _.get(a, orderBy.value, 0);
          let bCompare = _.get(b, orderBy.value, 0);
          if (aCompare < bCompare) {
            return -1;
          }
          if (aCompare > bCompare) {
            return 1;
          }
          return 0;
        });
      if (orderByDirection.value == Constants.ORDER_DIRECTION.DESC) {
        hs.reverse();
      }
      return hs;
    });

    const sortListener = e => {
      if (orderBy.value == e.key) {
        orderByDirection.value =
          orderByDirection.value == Constants.ORDER_DIRECTION.DESC
            ? Constants.ORDER_DIRECTION.ASC
            : Constants.ORDER_DIRECTION.DESC;
      } else {
        orderBy.value = e.key;
        orderByDirection.value = e.defaultSort || Constants.ORDER_DIRECTION.DESC;
      }

      return e;
    };

    const stockStructure = {
      columns: [
        {
          name: "code",
          class: "align-left",
          header: {
            component: HeaderSort,
            value: () => {
              return {
                options: [
                  {
                    label: "Code",
                    key: `code`,
                    defaultSort: Constants.ORDER_DIRECTION.ASC
                  }
                ],
                orderBy: orderBy.value,
                orderByDirection: orderByDirection.value
              };
            },
            listeners: {
              sort: sortListener
            }
          },
          content: {
            value: ({ code }) => {
              return `<strong class="common-color-blue">${Functions.Utils.cleanCode(code)}</strong>`;
            }
          },
          grid: "55px"
        },
        {
          name: "name",
          class: "align-left",
          header: {
            component: HeaderSort,
            value: () => {
              return {
                options: [
                  {
                    label: "Name",
                    key: `name`,
                    defaultSort: Constants.ORDER_DIRECTION.ASC
                  }
                ],
                orderBy: orderBy.value,
                orderByDirection: orderByDirection.value
              };
            },
            listeners: {
              sort: sortListener
            }
          },
          content: {
            value: ({ code }) => {
              let company = store.getters["Company/company"](Functions.Utils.cleanCode(code));
              return company.companyName || "-";
            }
          },
          grid: [
            {
              to: "mobile",
              grid: null
            },
            {
              grid: "1fr"
            }
          ]
        },
        {
          name: "luxBreakdown",
          class: "noPadding",
          header: {
            component: HeaderSort,
            value: () => {
              return {
                options: [
                  {
                    label: "growth",
                    key: `dataItems['lux.growth'].value`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "value",
                    key: `dataItems['lux.value'].value`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "balanced",
                    key: `dataItems['lux.balanced'].value`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "income",
                    key: `dataItems['lux.income'].value`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "trend",
                    key: `dataItems['lux.trend'].value`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  }
                ],
                orderBy: orderBy.value,
                orderByDirection: orderByDirection.value
              };
            },
            listeners: {
              sort: sortListener
            }
          },
          content: {
            component: LuxBreakdownInList,
            value: data => {
              return {
                company: {
                  companyData: {
                    luxScore: data.dataItems
                  }
                }
              };
            }
          },
          grid: "1fr"
        },
        {
          name: "price",
          header: {
            component: SelectInput,
            value: () => {
              return {
                modelValue: currentPrice.value,
                options: [
                  {
                    label: "Price 1D",
                    key: `companyData.priceChange["price.change.1D"]`
                  },
                  {
                    label: "Price 1W",
                    key: `companyData.priceChange["price.change.1W"]`
                  },
                  {
                    label: "Price 1M",
                    key: `companyData.priceChange["price.change.1M"]`
                  },
                  {
                    label: "Price 3M",
                    key: `companyData.priceChange["price.change.3M"]`
                  },
                  {
                    label: "Price 6M",
                    key: `companyData.priceChange["price.change.6M"]`
                  },
                  {
                    label: "Price 1Y",
                    key: `companyData.priceChange["price.change.1Y"]`
                  }
                ]
              };
            },
            listeners: {
              "update:modelValue": e => {
                currentPrice.value = e;
                return e;
              }
            }
          },
          content: {
            component: PriceChange,
            value: ({ code }) => {
              let company = store.getters["Company/company"](Functions.Utils.cleanCode(code));
              return {
                percentage: _.get(company, `${currentPrice.value}.changePC`, 0),
                dollar: _.get(company, `${currentPrice.value}.changeDollar`, 0)
              };
            }
          },
          grid: [
            {
              to: "tablet",
              grid: null
            },
            {
              grid: "150px"
            }
          ]
        },
        {
          name: "updated",
          header: {
            component: HeaderSort,
            value: () => {
              return {
                options: [
                  {
                    label: "Last updated",
                    key: `dataItems.PriceAdjusted.date`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  }
                ],
                orderBy: orderBy.value,
                orderByDirection: orderByDirection.value
              };
            },
            listeners: {
              sort: sortListener
            }
          },
          content: {
            value: ({ code }) => {
              let company = store.getters["Company/company"](Functions.Utils.cleanCode(code));
              return Functions.Dates.friendlyDateTimeText(
                _.get(company, `companyData.priceChange.['price.change.1D'].date`, 0)
              );
            }
          },
          grid: [
            {
              to: "phablet",
              grid: null
            },
            {
              to: "tablet",
              grid: "0.5fr"
            },
            {
              grid: "0.75fr"
            }
          ]
        },
        {
          name: "navigate",
          content: {
            component: Icon,
            value: () => {
              return {
                class: "navigate common-color-blue",
                icon: "arrow-right"
              };
            }
          },
          grid: "50px"
        }
      ]
    };

    onMounted(() => {
      currentPrice.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "currentPrice"
        }) || currentPrice.value;
      orderBy.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "orderBy"
        }) || orderBy.value;
      orderByDirection.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "orderByDirection"
        }) || orderByDirection.value;
      getData();
    });
    watch(
      () => currentPrice.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "currentPrice",
          value: currentPrice.value
        });
      }
    );
    watch(
      () => orderBy.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "orderBy",
          value: orderBy.value
        });
      }
    );
    watch(
      () => orderByDirection.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "orderByDirection",
          value: orderByDirection.value
        });
      }
    );

    return {
      Functions,
      loadingData,
      holdings,
      stockStructure,
      currentPrice
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include maxWidth($size-breakpoint-wide);
  padding: addSpace(2);
  background-image: url("/img/svg/star.svg");
  background-repeat: no-repeat;
  background-size: 90% 90%;
  background-position: -100% -100%;

  @include mq($to: phablet) {
    justify-content: flex-start;
  }

  .watchlist {
    width: 100%;
  }

  .empty {
    padding: addSpace(10) 0;
    font-size: $text-lead;
  }

  .more {
    padding: addSpace(10) 0;
    font-size: $text-small;
    font-style: italic;
    .icon {
      color: $color-blue;
    }
  }
}
</style>
