<template>
  <div class="priceChange" :class="classObject" :style="cssVars">
    <div class="percentage">
      <Icon v-if="!zero" :icon="trendIcon" />
      {{ Functions.Formatting.percentage(Math.abs(displayPercentage), 2) }}
    </div>
    <div class="change">
      (<Icon v-if="!zero" :icon="trendIcon" /> {{ Functions.Formatting.addCommas(Math.abs(displayDollar), 2) }})
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import Functions from "@/functions";
import Icon from "@/components/atoms/Icon.vue";
export default {
  name: "PriceChange",
  props: {
    percentage: { type: Number, default: 0 },
    dollar: { type: Number, default: 0 },
    animate: { type: Boolean, default: true }
  },
  components: {
    Icon
  },
  setup(props) {
    const displayPercentage = ref(0);
    const intervalPercentage = ref(null);
    const displayDollar = ref(0);
    const intervalDollar = ref(null);

    const cssVars = computed(() => {
      return {
        "--trend": trend
      };
    });

    const classObject = computed(() => {
      return {
        ["priceChange--trend-" + trendColor.value]: !zero.value
      };
    });

    const zero = computed(() => {
      return displayDollar.value == 0;
    });

    const trend = computed(() => {
      return zero.value ? 0 : displayDollar.value > 0 ? 1 : -1;
    });

    const trendIcon = computed(() => {
      return trend.value > 0 ? "plus" : "minus";
    });

    const trendColor = computed(() => {
      return trend.value > 0 ? "green" : "red";
    });

    const animateValue = (from, to, pointer) => {
      window.clearInterval(pointer.value);
      from.value = Number(from.value);
      to = Number(to);
      if (!props.animate) {
        from.value = to;
      }
      if (from.value == to) {
        return;
      }
      pointer.value = window.setInterval(() => {
        let change = (to - from.value) / 10;
        if (Math.abs(change) < 0.1) {
          from.value = to;
          window.clearInterval(pointer.value);
          return;
        } else {
          from.value = from.value + change;
        }
      }, 20);
    };

    watch(
      () => props.percentage,
      () => {
        animateValue(displayPercentage, props.percentage, intervalPercentage);
      }
    );
    watch(
      () => props.dollar,
      () => {
        animateValue(displayDollar, props.dollar, intervalDollar);
      }
    );

    const cleanValues = () => {
      displayPercentage.value = props.percentage;
      displayDollar.value = props.dollar;
      window.clearInterval(intervalPercentage.value);
      window.clearInterval(intervalDollar.value);
    };

    onMounted(() => {
      animateValue(displayPercentage, props.percentage, intervalPercentage);
      animateValue(displayDollar, props.dollar, intervalDollar);
    });

    onBeforeUnmount(() => {
      cleanValues();
    });

    return {
      Functions,
      cssVars,
      classObject,
      zero,
      trend,
      trendIcon,
      displayPercentage,
      displayDollar
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.priceChange {
  @include transition;
  @include flexCentered;
  position: relative;

  & > div {
    margin: 0 addSpace(1);
  }

  .icon {
    font-size: 0.75em;
  }

  &--trend-green {
    color: darken($color-success, 10%);
  }
  &--trend-red {
    color: darken($color-error, 5%);
  }
}
</style>
