<template>
  <router-link :to="to">
    <div class="dynamicCard" ref="dynamicCardRef" :class="{ header: noData }" :style="cssVars" @click="onClick">
      <div
        class="column"
        :class="[s.name, s.class]"
        v-for="s in structure.columns"
        :key="s.name"
        :style="{ display: hasGrid(s) ? null : 'none' }"
      >
        <div v-if="noData">
          <component
            v-if="getComponent(s, 'header')"
            :is="getComponent(s, 'header')"
            v-bind="getValue(s, 'header')"
            v-on="getEvents(s, 'header')"
          />
          <div v-if="!getComponent(s, 'header')" v-html="getValue(s, 'header')"></div>
        </div>
        <div v-if="!noData">
          <component
            v-if="getComponent(s, 'content')"
            :is="getComponent(s, 'content')"
            v-bind="getValue(s, 'content')"
            v-on="getEvents(s, 'content')"
          />
          <div v-if="!getComponent(s, 'content')" v-html="getValue(s, 'content')"></div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import _ from "lodash";
import Constants from "@/assets/constants";
import Functions from "@/functions";
export default {
  name: "DynamicCard",
  props: {
    structure: { type: Object, default: () => {} },
    data: { type: Object, default: () => {} },
    to: { type: String, default: "" }
  },
  emits: ["click"],
  setup(props, context) {
    const dynamicCardRef = ref(null);
    const cardWidth = ref(0);

    const onClick = event => {
      context.emit("click", event);
    };

    const noData = computed(() => {
      return _.isEmpty(props.data);
    });
    const code = computed(() => {
      return _.get(props.data, "code", null);
    });

    const getComponent = (structure, key) => {
      let component = _.get(structure, `${key}.component`, null);
      return _.isFunction(component) ? component(props.data) : component;
    };

    const getValue = (structure, key) => {
      let value = _.get(structure, `${key}.value`, null);
      return _.isFunction(value) ? value(props.data) : value;
    };

    const getEvents = (structure, key) => {
      return _.get(structure, `${key}.listeners`, {});
    };

    const getCardWidth = () => {
      cardWidth.value = _.get(dynamicCardRef.value, "clientWidth", 0);
    };

    const gridColumns = computed(() => {
      return props.structure.columns.reduce((a, s) => {
        if (_.isString(s.grid)) {
          a.push({ name: s.name, grid: s.grid });
        } else {
          let properGrid = null;
          _.forEach(s.grid, g => {
            let isSmallerThanBrackepoint = Constants.BREAKPOINTS[g.to] >= cardWidth.value && !properGrid;
            let isFallback = !g.to && !properGrid;
            if (isSmallerThanBrackepoint || isFallback) {
              properGrid = g;
            }
          });
          if (properGrid) {
            a.push({ name: s.name, grid: properGrid.grid });
          }
        }
        return a;
      }, []);
    });

    const hasGrid = structure => {
      let current = gridColumns.value.find(s => s.name == structure.name);
      return !!_.get(current, `grid`, false);
    };

    const gridColumnsStyle = computed(() => {
      return gridColumns.value.reduce((a, s) => {
        if (s.grid) {
          a += s.grid + " ";
        }
        return a;
      }, "");
    });

    const cssVars = computed(() => {
      return {
        "grid-template-columns": gridColumnsStyle.value
      };
    });

    onMounted(() => {
      window.addEventListener("resize", getCardWidth);
      getCardWidth();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", getCardWidth);
    });

    return {
      Functions,
      dynamicCardRef,

      onClick,

      noData,
      code,
      getComponent,
      getValue,
      getEvents,
      hasGrid,
      cssVars
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

a {
  display: block;
  color: inherit;
  text-decoration: none;
}

.dynamicCard {
  @include transition;
  @include edge;
  font-size: $text-small;
  color: $color-black;
  background: $color-white;
  display: grid;
  margin-top: 1px;
  cursor: pointer;

  &.header {
    cursor: auto;
  }

  &.header {
    background: none;

    :deep(.select-input-field__input) {
      font-size: $text-small;
      box-shadow: none;
    }
  }

  & > * {
    @include flexCentered(column);
    padding: addSpace(2);
  }

  .align-left {
    align-items: flex-start;
  }
  .align-right {
    align-items: flex-end;
  }

  .navigate {
    @include transition;
  }
  &:hover:not(.header) {
    filter: brightness(95%);
    .navigate {
      transform: translateX(addSpace(0.5));
    }
  }
}
</style>
