<template>
  <div class="page">
    <transition name="maxH">
      <DynamicCard class="header" :structure="peersStructure" v-if="peers.length > 0" />
    </transition>
    <transition-group name="maxH">
      <DynamicCard
        class="card"
        :class="{ loading: loading }"
        v-for="holding in peers"
        :key="`holding-${holding}`"
        :data="holding"
        :structure="peersStructure"
        :to="'/company/' + Functions.Utils.cleanCode(holding.code)"
      />
    </transition-group>
    <transition name="maxH">
      <div v-if="loading && peers.length !== listLength">
        <div class="skLoadingCard skLoading"></div>
        <div class="skLoadingCard skLoading"></div>
        <div class="skLoadingCard skLoading"></div>
        <div class="skLoadingCard skLoading"></div>
      </div>
    </transition>
    <Button
      class="loadMore"
      v-bind="Functions.Presets.Button.FullWhite"
      v-if="peers.length > 0"
      text="Load more"
      icon="arrow-down"
      icon-position="right"
      :disabled="loading"
      @click="loadMore"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import DynamicCard from "@/components/atoms/DynamicCard.vue";
import HeaderSort from "@/components/atoms/HeaderSort.vue";
import Icon from "@/components/atoms/Icon.vue";
import Button from "@/components/atoms/Button.vue";
import LuxBreakdownInList from "@/components/atoms/LuxBreakdownInList.vue";
import Functions from "@/functions";
import Constants from "@/assets/constants";
export default {
  name: "Peers",
  props: {
    company: { type: Object, default: () => {} }
  },
  components: {
    DynamicCard,
    Button
  },
  setup(props) {
    const store = useStore();
    const lsContext = "company/peers";
    const orderBy = ref("lux.balanced");
    const orderByDirection = ref(Constants.ORDER_DIRECTION.DESC);
    const listLength = ref(10);
    const loading = ref(false);

    const loadMore = () => {
      listLength.value += 10;
    };

    const filterPeers = _.throttle(
      function() {
        loading.value = true;
        store
          .dispatch("Company/peers", {
            code: _.get(props.company, "information.companyCode", null),
            orderBy: orderBy.value,
            orderByDirection: orderByDirection.value,
            limit: listLength.value
          })
          .finally(() => {
            loading.value = false;
          });
      },
      200,
      { trailing: false }
    );

    const peers = computed(() => {
      let peers = _.get(props.company, "peers", []);
      peers = peers.map(p => {
        p.code = _.get(p, "company.mainSecurityCode");
        return p;
      });
      return peers;
    });

    const sortListener = e => {
      if (orderBy.value == e.key) {
        orderByDirection.value =
          orderByDirection.value == Constants.ORDER_DIRECTION.DESC
            ? Constants.ORDER_DIRECTION.ASC
            : Constants.ORDER_DIRECTION.DESC;
      } else {
        orderBy.value = e.key;
        orderByDirection.value = e.defaultSort || Constants.ORDER_DIRECTION.DESC;
      }

      return e;
    };

    const peersStructure = {
      columns: [
        {
          name: "code",
          class: "align-left",
          header: {
            component: HeaderSort,
            value: () => {
              return {
                options: [
                  {
                    label: "Code",
                    key: `code`,
                    defaultSort: Constants.ORDER_DIRECTION.ASC
                  }
                ],
                orderBy: orderBy.value,
                orderByDirection: orderByDirection.value
              };
            },
            listeners: {
              sort: sortListener
            }
          },
          content: {
            value: data => {
              return `<strong class="common-color-blue">${Functions.Utils.cleanCode(
                data.company.mainSecurityCode
              )}</strong>`;
            }
          },
          grid: "55px"
        },
        {
          name: "name",
          class: "align-left",
          header: {
            component: HeaderSort,
            value: () => {
              return {
                options: [
                  {
                    label: "Name",
                    key: `name`,
                    defaultSort: Constants.ORDER_DIRECTION.ASC
                  }
                ],
                orderBy: orderBy.value,
                orderByDirection: orderByDirection.value
              };
            },
            listeners: {
              sort: sortListener
            }
          },
          content: {
            value: data => {
              return data.company.commonName;
            }
          },
          grid: [
            {
              to: "mobile",
              grid: null
            },
            {
              grid: "1fr"
            }
          ]
        },
        {
          name: "luxBreakdown",
          class: "noPadding",
          header: {
            component: HeaderSort,
            value: () => {
              return {
                options: [
                  {
                    label: "growth",
                    key: `lux.growth`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "value",
                    key: `lux.value`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "balanced",
                    key: `lux.balanced`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "income",
                    key: `lux.income`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  },
                  {
                    label: "trend",
                    key: `lux.trend`,
                    defaultSort: Constants.ORDER_DIRECTION.DESC
                  }
                ],
                orderBy: orderBy.value,
                orderByDirection: orderByDirection.value
              };
            },
            listeners: {
              sort: sortListener
            }
          },
          content: {
            component: LuxBreakdownInList,
            value: data => {
              return {
                company: {
                  companyData: {
                    luxScore: data.dataItems
                  }
                }
              };
            }
          },
          grid: "1fr"
        },
        {
          name: "navigate",
          content: {
            component: Icon,
            value: () => {
              return {
                class: "navigate common-color-blue",
                icon: "arrow-right"
              };
            }
          },
          grid: "50px"
        }
      ]
    };

    onMounted(() => {
      orderBy.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "orderBy"
        }) || orderBy.value;
      orderByDirection.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "orderByDirection"
        }) || orderByDirection.value;
      filterPeers();
    });
    watch(
      () => listLength.value,
      () => {
        filterPeers();
      }
    );
    watch(
      () => orderBy.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "orderBy",
          value: orderBy.value
        });
        filterPeers();
      }
    );
    watch(
      () => orderByDirection.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "orderByDirection",
          value: orderByDirection.value
        });
        filterPeers();
      }
    );

    return {
      Functions,
      orderBy,
      orderByDirection,
      listLength,
      loading,
      loadMore,
      filterPeers,
      peers,
      peersStructure
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  padding: addSpace(2);
  margin-bottom: addSpace(8);

  @include mq($to: medium) {
    padding: 0;
  }

  .loading {
    pointer-events: none;
    opacity: 0.5;
  }

  .loadMore {
    margin: addSpace(2) 0;
  }
}
</style>
