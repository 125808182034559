<template>
  <div class="financialsTable">
    <div>
      <div class="financeTable header">
        <div class="label"></div>
        <div class="current">Current</div>
        <div class="forecast">Forecast</div>
        <div class="chart"></div>
      </div>
    </div>
    <div v-for="(data, k) in structure" :key="data.label || k" class="financeTable">
      <div class="label" v-text="data.label"></div>
      <div class="current" v-text="formattedValue(data, 'current')"></div>
      <div class="forecast" v-text="formattedValue(data, 'forecast')"></div>
      <div class="chart" v-if="directionValue(data)">
        <Icon
          :icon="directionValue(data) >= 0 ? 'arrow-up' : 'arrow-down'"
          :class="{
            'common-color-success': directionValue(data) >= 0,
            'common-color-error': directionValue(data) < 0
          }"
        />
        <span class="value" v-text="Functions.Formatting.percentage(directionValue(data) * 100)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Icon from "@/components/atoms/Icon.vue";
import Functions from "@/functions";
export default {
  name: "FinancialsTable",
  props: {
    structure: { type: [Array, Object], default: null }
  },
  components: {
    Icon
  },
  setup() {
    const formattedValue = (data, key) => {
      let v = _.get(data, `${key}.value`, null);
      if (_.isNull(v)) {
        return "-";
      }
      return _.isFunction(data.formatting) ? data.formatting(v) : v;
    };

    const directionValue = d => {
      let c = _.get(d.current, "value");
      let f = _.get(d.forecast, "value");
      if (!c || !f) {
        return null;
      }
      let p = f / c;
      return p - 1;
    };

    return {
      Functions,
      formattedValue,
      directionValue
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.financialsTable {
  .financeTable {
    @include transition;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 0.5fr;

    &.header {
      font-weight: 400;
      font-size: 0.8em;
      .forecast {
        border-top-right-radius: 25px;
      }
    }

    &:nth-child(2n) {
      background: mix($color-white, $color-blue, 97%);
    }
    & > * {
      padding: addSpace(2);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .forecast {
      background: rgba($color-blue, 0.1);
    }
  }

  @include mq($to: phablet) {
    .financeTable {
      grid-template-columns: 2fr 1fr 1fr 36px;
    }
    .chart .value {
      display: none;
    }
  }
}
</style>
