<template>
  <div class="tabs">
    <div
      class="items"
      :class="{ current: isCurrent(item, index) }"
      v-for="(item, index) in options"
      :key="item.key"
      @click="$emit('change', item.key)"
    >
      {{ item.label }}
      <Icon :icon="item.icon" v-if="item.icon" />
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  name: "Tabs",
  components: {
    Icon
  },
  props: {
    options: { type: Array, default: () => [] },
    current: { type: [String, Object], default: null }
  },
  emits: ["change"],
  setup(props) {
    const isCurrent = (v, i) => {
      if (!props.current && i == 0) {
        return true;
      }
      return v.key == props.current;
    };

    return {
      isCurrent
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.tabs {
  @include flexCentered;

  .items {
    @include transition;
    padding: addSpace(2);
    border-bottom: 2px solid transparent;
    cursor: pointer;
    &:hover {
      border-bottom-color: rgba($color-blue, 0.25);
    }
    &.current {
      border-bottom-color: $color-blue;
    }
  }
}
</style>
