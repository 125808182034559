<template>
  <div class="luxBreakdownInList">
    <div class="box">
      <div class="item" v-for="item in itemList" :key="item" :class="luxClassByType(item)" :style="cssVarsByType(item)">
        <div class="fill"></div>
        <div class="value" v-html="luxLabelByType(item)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Functions from "@/functions";
export default {
  name: "LuxBreakdownInList",
  props: {
    company: { type: Object, default: () => {} }
  },
  setup(props) {
    const itemList = ["growth", "value", "balanced", "income", "trend"];

    const luxByType = type => {
      return _.get(props.company, `companyData.luxScore["lux.${type}"].value`, null);
    };
    const luxPercentageByType = type => {
      return luxByType(type) * 10;
    };
    const luxLabelByType = type => {
      return Functions.Formatting.restrictDecimals(luxByType(type), 0);
    };

    const luxClassByType = type => {
      let lux = luxByType(type);
      return {
        [`lux-n-${Functions.Formatting.restrictDecimals(lux, 0)}`]: true,
        [`lux-l-${Functions.Company.luxToLabel(lux)}`]: true
      };
    };

    const cssVarsByType = type => {
      return {
        "--lux": luxByType(type),
        "--h-lux": Functions.Math.nonLinearPercentage(luxPercentageByType(type)) + "%",
        "--bg-lux": luxByType(type) / 10
      };
    };

    return {
      itemList,
      luxByType,
      luxPercentageByType,
      luxLabelByType,
      luxClassByType,
      cssVarsByType
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";
$wave-color: mix($color-blue, $color-white, 10%);

.luxBreakdownInList {
  position: relative;
  width: 250px;
  height: 35px;

  .box {
    @include flexCentered;
    @include transition;
    position: absolute;
    width: 100%;
    height: 100%;

    .item {
      @include flexCentered(column);
      position: relative;
      flex-grow: 1;
      height: 100%;

      & + .item {
        border-left: 2px solid transparent;
      }

      .fill {
        @include transition;
        z-index: z("flat");
        position: absolute;
        width: 100%;
        height: var(--h-lux);
        bottom: 0;
        left: 0;
        background-color: rgba($color-blue, 0.15);
        background-image: linear-gradient(
          45deg,
          rgba($color-white, 0.75) 16.67%,
          transparent 16.67%,
          transparent 50%,
          rgba($color-white, 0.75) 50%,
          rgba($color-white, 0.75) 66.67%,
          transparent 66.67%,
          transparent 100%
        );
        background-size: 6px 6px;
        border-top-left-radius: 10px;
      }

      .value {
        z-index: z("front");
        position: relative;
        font-size: 1.1em;
        height: 10px;
      }
    }

    .lux-l-Radiant {
      color: $color-blue;
      font-weight: 600;
      .fill {
        background-image: linear-gradient(
          45deg,
          rgba($color-light, 1) 16.67%,
          transparent 16.67%,
          transparent 50%,
          rgba($color-light, 1) 50%,
          rgba($color-light, 1) 66.67%,
          transparent 66.67%,
          transparent 100%
        );
      }
    }
    .lux-l-Bright {
      color: mix($color-blue, $color-light-gray, 60%);
      font-weight: 400;
      .fill {
        background-image: linear-gradient(
          45deg,
          mix($color-light, $color-white, 15%) 16.67%,
          transparent 16.67%,
          transparent 50%,
          mix($color-light, $color-white, 15%) 50%,
          mix($color-light, $color-white, 15%) 66.67%,
          transparent 66.67%,
          transparent 100%
        );
      }
    }
    .lux-l-Neutral {
      color: mix($color-blue, $color-light-gray, 40%);
      font-weight: 400;
    }
    .lux-l-Dull {
      color: $color-gray;
    }
  }
}
</style>
